.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: transparent;
}

form {
    max-width: 600px;
    width: 100%;
    background: transparent;
    padding: 25px 25px 30px;
    margin: 25px;
}

form h1 {
    text-align: center;
    margin-top: 1rem;
    color: #C770F0;
}

form .input-box {
    margin-top: 20px;
    text-align: left;
    color: white;
}

.input-box .field {
    width: 100%;
    height: 50px;
    background: transparent;
    border: 1px solid #dddddd2a;
    outline: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 16px;
    color: white;
}

.input-box .field.mess {
    height: 150px;
    resize: none;
}

form button {
    width: 100%;
    height: 55px;
    /* background: #C770F0; */
    background: transparent;
    border: 1px solid  #C770F0;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-top: 25px;
    /* transition: .5s; */
}

form button:hover {
    background: #c770f0be;
}
